import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [task, setTask] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const apiCalledRef = useRef(false);
  const cacheBuster = Date.now();
  const [subscription, setSubscription] = useState([]);
  const [subscriptionTraining, setSubscriptionTraining] = useState([]);
  const [subscriptionStaff, setSubscriptionStaff] = useState([]);
  const [subscriptionMaster, setSubscriptionMaster] = useState([]);
  const [plans, setPlans] = useState([]);
  const [residentDetails, setResidentDetails] = useState([]);
  const [error, setError] = useState();

  const cacheExpiryTime = 3600 * 1000; // 1 hour in milliseconds

  const specificPathsForFunctions = {
    fetchSubscriptionData: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff"],
    fetchSubscriptionStaffData: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff"],
    fetchSubscriptionTrainingData: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff"],
    fetchPlans: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff", "/trial-subscription-staff"],
  };

  const fetchUser = async () => {


    try {
      // When Data is avilable in localStorage, use it
      const cachedUser = localStorage.getItem("user");
      const cacheTimestamp = localStorage.getItem("userTimestamp");

      // Check if cached user data is valid
      if (cachedUser && cacheTimestamp) {
        const isCacheValid = Date.now() - parseInt(cacheTimestamp, 10) < cacheExpiryTime;

        if (isCacheValid) {
          setUser(JSON.parse(cachedUser));
          return; // Exit if cache is valid
        } else {
          clearCachedUser(); // Clear expired cache
        }
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/currentuser/?cacheBuster=${cacheBuster}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`, // Add token to the Authorization header
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          clearCachedUser();
          localStorage.removeItem("token"); // Clear invalid token
          navigate("/login"); // Redirect to login
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        // Cache the fetched user data in localStorage
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("userTimestamp", Date.now()); // Update timestamp
        setUser(data); // Set user data to state
      }
    } catch (err) {
      // console.error("Failed to fetch user:", err);
    }
  };


  const fetchSubscriptionData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}`) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        setSubscription(data);
      }
    } catch (err) {
      // console.error("Failed to fetch Trainings:", err);
    }
  };

  const fetchSubscriptionStaffData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}&module=staff`) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        setSubscriptionStaff(data);
      }
    } catch (err) {
      // console.error("Failed to fetch Trainings:", err);
    }
  };

  const fetchSubscriptionTrainingData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}&module=trainings`) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        setSubscriptionTraining(data);
      }
    } catch (err) {
      // console.error("Failed to fetch Trainings:", err);
    }
  };

  // const fetchSubscriptionMasterData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}&module=master`) => {
  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       if (response.status === 401) {
  //         localStorage.removeItem("token");
  //         navigate("/login");
  //       } else {
  //         throw new Error(`Error: ${response.status} ${response.statusText}`);
  //       }
  //     } else {
  //       const data = await response.json();
  //       setSubscriptionMaster(data);
  //     }
  //   } catch (err) {
  //     // console.error("Failed to fetch Trainings:", err);
  //   }
  // };

  const fetchPlans = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/plans/?cacheBuster=${cacheBuster}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`, // Add token to the Authorization header
        },
      });

      if (!response.ok) {
        if (response.status === 401) {

          localStorage.removeItem("token"); // Clear invalid token
          navigate("/login"); // Redirect to login
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();

        setPlans(data); // Set user data to state
      }
    } catch (err) {
      // console.error("Failed to fetch user:", err);
    }
  };

  const fetchResidentDetails = async () => {
    try {
      // When Data is avilable in localStorage, use it
      const cachedResident = localStorage.getItem("resident");
      const cacheTimestamp = localStorage.getItem("residentTimestamp");

      // Check if cached user data is valid
      if (cachedResident && cacheTimestamp) {
        const isCacheValid = Date.now() - parseInt(cacheTimestamp, 10) < cacheExpiryTime;

        if (isCacheValid) {
          setResidentDetails(JSON.parse(cachedResident));
          return; // Exit if cache is valid
        } else {
          clearCachedUser(); // Clear expired cache
        }
      }
      
      // const url = `${process.env.REACT_APP_API_BASE_URL}/residents/?fields=first_name&last_name`;

      const url = `${process.env.REACT_APP_API_BASE_URL}/residents/?fields=id,first_name,last_name,date_of_birth,room_number,bed,is_active,avatar,examination_due_date,examination_requested,has_examiners_assigned`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.status === 401) {
          setError("Unauthorized access. Redirecting to login...");
          localStorage.removeItem("token");
          navigate("/login");
          return;
        }
        setError(data.detail || "Failed to fetch active residents count.");
        return;
      }
      
      const no1823Resident = data;

      localStorage.setItem("resident", JSON.stringify(no1823Resident));
      localStorage.setItem("residentTimestamp", Date.now()); // Update timestamp
      
      setResidentDetails(no1823Resident);
    } catch (err) {
      setError(err.message);
    }
  };


  const isPathIncluded = (path, specificPaths) => {
    return specificPaths.some((specificPath) => {
      const regex = new RegExp(`^${specificPath.replace(/:\w+/g, "\\w+")}$`);
      return regex.test(path);
    });
  };

  useEffect(() => {

    if (!token) {
      toast.error("No token found, redirecting to login...");
      navigate("/login");
      return;
    }

    // Check if user data is cached and valid
    const cachedUser = localStorage.getItem("user");
    const cacheTimestamp = localStorage.getItem("userTimestamp");
    const isCacheValid = cachedUser && cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10) < cacheExpiryTime);

    if (!isCacheValid) {
      fetchUser(); // Fetch user if cache is invalid or expired
    } else {
      setUser(JSON.parse(cachedUser)); // Set user from cache
    }



    if (!apiCalledRef.current) {
      apiCalledRef.current = true;

      fetchUser();
      fetchResidentDetails();
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchSubscriptionData)) {
        fetchSubscriptionData();
      }
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchSubscriptionStaffData)) {
        fetchSubscriptionStaffData();
      }
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchSubscriptionTrainingData)) {
        fetchSubscriptionTrainingData();
      }
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchPlans)) {
        fetchPlans();
      }
      // if (!excludedPathsForFunctions.fetchSubscriptionMasterData.includes(location.pathname)) {
      //   fetchSubscriptionMasterData();
      // }

    }

  }, [location.pathname]);

  const clearCachedUser = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userTimestamp");
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, task, subscription, subscriptionStaff, subscriptionTraining, plans, residentDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};